import React, { useEffect, useRef, useState } from 'react'
import UseChatBot from '../Hooks/chatBot/useChatBot';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import AdvertiserDetails from '../Hooks/Dashboard/useAdvertiser';

const ChatBotAdv = () => {
    const {
        selectedAdvertiserIds,
        setSelectedAdvertiserIds,
        loadingAdvertisers,
        loadingSession,
        initiateChatSession,
    } = UseChatBot();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedAdvertiserNames, setSelectedAdvertiserNames] = useState([]);

    const dropdownRef = useRef(null);
    const { advertiser, getAdvertiser, loading: advLoading } = AdvertiserDetails();

    useEffect(() => {
        if (!localStorage.getItem("sessionId")) {
            getAdvertiser();
        }
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleDropdownToggle = (event) => {
        event.stopPropagation();
        setDropdownOpen((prev) => !prev);
    };

    const handleAdvertiserSelection = (name, id) => {
        setSelectedAdvertiserNames((prev) => {
            const updatedNames = prev.includes(name)
                ? prev.filter((n) => n !== name)
                : [...prev, name];
            const updatedIds = advertiser
                ?.filter((adv) => updatedNames?.includes(adv?.name))
                ?.map((adv) => adv?.id)
                ?.join(",");
            setSelectedAdvertiserIds(updatedIds);
            return updatedNames;
        });
    };

    const handleSessionStart = () => {
        initiateChatSession(selectedAdvertiserIds);
        localStorage.getItem("sessionId");
    };

    return (
        <div className="relative w-full shadow-lg border border-gray-400 bg-gray-50 p-10" ref={dropdownRef}>
            <h2 className="text-lg sm:text-xl md:text-2xl font-extrabold text-[#1c4546]">
                ChatBot
            </h2>
            <label className="block text-sm md:text-base font-medium text-gray-800 mb-1">
                Select Advertisers
            </label>
            <div
                className="border flex justify-between items-center border-gray-300 text-sm md:text-base font-medium rounded-lg px-3 py-2 cursor-pointer bg-gray-100 
                                  hover:bg-gray-200 transition-all"
                onClick={handleDropdownToggle}
                disabled={loadingAdvertisers || advLoading}
            >
                {loadingAdvertisers || advLoading ? (
                    <div className="flex items-center space-x-2 text-[#1c4546]">
                        <div className="animate-bounce w-2 h-2 rounded-full bg-[#1c4546]"></div>
                        <div className="animate-bounce200 w-2 h-2 rounded-full bg-[#1c4546]"></div>
                        <div className="animate-bounce400 w-2 h-2 rounded-full bg-[#1c4546]"></div>
                        <span className="ml-1">Loading Advertisers...</span>
                    </div>
                ) : selectedAdvertiserNames.length > 0 ? (
                    <span className="font-medium text-[#1c4546]">
                        Selected ({selectedAdvertiserNames.length})
                    </span>
                ) : (
                    "Choose Advertisers"
                )}
                <span className="ml-2">
                    {dropdownOpen ? (
                        <FaChevronUp className="text-[#1c4546]" />
                    ) : (
                        <FaChevronDown className="text-[#1c4546]" />
                    )}
                </span>
            </div>

            {dropdownOpen && (
                <div className="absolute z-20 mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-44 lg:max-h-64 2xl:max-h-80 overflow-auto">
                    {advertiser?.map((adv, index) => (
                        <label
                            key={index}
                            className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                        >
                            <input
                                type="checkbox"
                                checked={selectedAdvertiserNames.includes(adv.name)}
                                onChange={() => handleAdvertiserSelection(adv.name, adv.id)}
                                className="form-checkbox h-4 w-4 text-[#1c4546] rounded-lg"
                            />
                            <span className="ml-2 text-gray-700 text-sm">{adv.name}</span>
                        </label>
                    ))}
                </div>
            )}
            <button
                onClick={handleSessionStart}
                disabled={!selectedAdvertiserIds || loadingSession}
                className={`w-56 mx-auto px-3 py-1.5 mt-4 text-sm md:text-base font-medium rounded-lg transition-all 
                                  ${!selectedAdvertiserIds || loadingSession
                        ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                        : "bg-[#1c4546] text-white hover:bg-[#122e2e] shadow-md"}`}
            >
                {loadingSession ? (
                    <div className="flex items-center justify-center space-x-2 text-[#1c4546]">
                        <div className="animate-bounce w-2 h-2 rounded-full bg-[#1c4546]"></div>
                        <div className="animate-bounce200 w-2 h-2 rounded-full bg-[#1c4546]"></div>
                        <div className="animate-bounce400 w-2 h-2 rounded-full bg-[#1c4546]"></div>
                        <span className="ml-2">Starting Session...</span>
                    </div>
                ) : (
                    "Start Session"
                )}
            </button>
        </div>
    )
}

export default ChatBotAdv