import React, { useState, useEffect, useRef } from "react";
import UseChatBot from "../Hooks/chatBot/useChatBot";
import Footer from "../template/Footer";
import Header from "../template/Header";
import ChatbotChart from "./chatbotChart";
import ChatBotAdv from "./chatBotAdv";

const ChatBot = () => {
    const {
        sessionId,
        loadingQuestion,
        error,
        submitQuestion,
    } = UseChatBot();

    const [question, setQuestion] = useState("");
    const [questionError, setQuestionError] = useState("");
    const [chatHistory, setChatHistory] = useState([]);

    const chatContainerRef = useRef(null);

    // Scroll to the bottom whenever chatHistory updates
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    const handleQuestionSubmit = async () => {
        if (!question.trim()) {
            setQuestionError("Question can't be empty");
            return;
        }

        setQuestionError("");
        
        const botResponse = await submitQuestion(question);

        if (botResponse) {
            const newChatEntry = {
                question,
                response: botResponse.Values?.map(item => ({
                    campaign: item[0],
                    value: item[1]
                })) || [],
                visualizationType: botResponse?.VisualizationType || "bar"
            };
            setChatHistory(prev => [...prev, newChatEntry]);
            setQuestion("");
        }

    };

    return (
        <>
            <Header />
            <div className="md:px-8 lg:px-5 lg:pt-2.5 flex flex-col pb-2.5 mt-16 mx-auto rounded-lg min-h-[83dvh] max-h-[84dvh] 2xl:max-h-[87dvh] space-y-4 
            ">
                {/* <h2 className="text-lg sm:text-xl md:text-2xl font-extrabold text-[#1c4546]">
                    ChatBot
                </h2> */}

                {error && (
                    <p className="text-sm md:text-base text-red-700 bg-red-100 px-3 py-1.5 rounded-lg border border-red-200 shadow-sm">
                        {error}
                    </p>
                )}

                {!sessionId && (
                    <ChatBotAdv />
                )}

                {sessionId && (
                    <div className="flex flex-col flex-grow overflow-hidden border">
                        <h2 className="text-lg pt-2 sm:text-xl md:text-2xl px-5 font-extrabold text-[#1c4546]">
                            ChatBot
                        </h2>
                        <div
                            ref={chatContainerRef}
                            className="flex-grow overflow-y-auto bg-gray-50"
                        >
                            {chatHistory?.length > 0 &&
                                <ChatbotChart chatHistory={chatHistory} />
                            }
                        </div>

                        <div className="sticky bottom-0">
                            <input
                                type="text"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                placeholder="Type your question here"
                                required
                                className="w-full mr-3 px-3 py-2 placeholder-gray-500 text-xs md:text-xs font-medium border border-gray-300 rounded-  focus:outline-none
                                focus:border-[#1c4546] pr-24"
                            />
                            <button
                                onClick={handleQuestionSubmit}
                                className={`absolute right-4 top-1/2 transform -translate-y-1/2 
                            ${loadingQuestion ? 'cursor-not-allowed bg-gray-300' : 'bg-[#1c4546] hover:bg-[#102c2d]'} 
                            px-3 py-1 text-xs md:text-xs text-white font-semibold rounded-lg w-[120px] md:w-[100px] shadow-md`}
                                disabled={loadingQuestion}
                            >
                                {loadingQuestion ? (
                                    <div className="flex items-center justify-center space-x-1">
                                        <div className="animate-bounce w-1.5 h-1.5 bg-[#1c4546] rounded-full"></div>
                                        <div className="animate-bounce200 w-1.5 h-1.5 bg-[#1c4546] rounded-full"></div>
                                        <div className="animate-bounce400 w-1.5 h-1.5 bg-[#1c4546] rounded-full"></div>
                                        <span className="ml-2 text-[#1c4546]">Asking...</span>
                                    </div>
                                ) : (
                                    'Ask'
                                )}
                            </button>
                        </div>
                        {questionError && (
                            <p className="text-red-500 text-[12px] mt-2 pl-2">{questionError}</p>
                        )}
                    </div>
                )}

            </div>
            <Footer />
        </>
    );
};

export default ChatBot;



// const chartData = chatHistory?.map((chat, index) => ({
//     name: `Q${index + 1}`,
//     value: chat?.response.Values?.map(item => ({
//         campaign: item[0],
//         value: item[1]
//     })) || [],
//     visualizationType: chat.visualizationType || "bar"
// }));

// console.log("chartdata - ", chartData)

// {/* {response && (
//     <div className="mt-8 bg-white rounded-lg shadow-md border border-gray-200 max-h-[30dvh] xl:max-h-[42vh] 2xl:max-h-[52dvh] overflow-auto">
//         <TableContainer component={Paper}>
//             <Table>
//                 <TableHead>
//                     <TableRow className="bg-gray-100">
//                         {response?.ColumnNames.map((columnName, index) => (
//                             <TableCell key={index} align="center" className="font-bold text-gray-700">
//                                 {columnName}
//                             </TableCell>
//                         ))}
//                     </TableRow>
//                 </TableHead>
//                 <TableBody>
//                     {response?.Values.map((row, rowIndex) => (
//                         <TableRow key={rowIndex} className="hover:bg-gray-50">
//                             {row.map((value, colIndex) => (
//                                 <TableCell key={colIndex} align="center" className="text-gray-700">
//                                     {value}
//                                 </TableCell>
//                             ))}
//                         </TableRow>
//                     ))}
//                 </TableBody>
//             </Table>
//         </TableContainer>
//     </div>
// )} */}

// Line Graph
// Multiple Line Graph
// Bar Graph
// Multiple Bar Graph
// Pie Chart
// Histogram

                    // <div className="relative" ref={dropdownRef}>
                    //     <label className="block text-sm md:text-base font-medium text-gray-800 mb-1">
                    //         Select Advertisers
                    //     </label>
                    //     <div
                    //         className="border flex justify-between items-center border-gray-300 text-sm md:text-base font-medium rounded-lg px-3 py-2 cursor-pointer bg-gray-100 
                    //         hover:bg-gray-200 transition-all"
                    //         onClick={handleDropdownToggle}
                    //         disabled={loadingAdvertisers || advLoading}
                    //     >
                    //         {loadingAdvertisers || advLoading ? (
                    //             <div className="flex items-center space-x-2 text-[#1c4546]">
                    //                 <div className="animate-bounce w-2 h-2 rounded-full bg-[#1c4546]"></div>
                    //                 <div className="animate-bounce200 w-2 h-2 rounded-full bg-[#1c4546]"></div>
                    //                 <div className="animate-bounce400 w-2 h-2 rounded-full bg-[#1c4546]"></div>
                    //                 <span className="ml-1">Loading Advertisers...</span>
                    //             </div>
                    //         ) : selectedAdvertiserNames.length > 0 ? (
                    //             <span className="font-medium text-[#1c4546]">
                    //                 Selected ({selectedAdvertiserNames.length})
                    //             </span>
                    //         ) : (
                    //             "Choose Advertisers"
                    //         )}
                    //         <span className="ml-2">
                    //             {dropdownOpen ? (
                    //                 <FaChevronUp className="text-[#1c4546]" />
                    //             ) : (
                    //                 <FaChevronDown className="text-[#1c4546]" />
                    //             )}
                    //         </span>
                    //     </div>

                    //     {dropdownOpen && (
                    //         <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-48 overflow-auto">
                    //             {advertiser?.map((adv, index) => (
                    //                 <label
                    //                     key={index}
                    //                     className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                    //                 >
                    //                     <input
                    //                         type="checkbox"
                    //                         checked={selectedAdvertiserNames.includes(adv.name)}
                    //                         onChange={() => handleAdvertiserSelection(adv.name, adv.id)}
                    //                         className="form-checkbox h-4 w-4 text-[#1c4546] rounded-lg"
                    //                     />
                    //                     <span className="ml-2 text-gray-700 text-sm">{adv.name}</span>
                    //                 </label>
                    //             ))}
                    //         </div>
                    //     )}
                    //     <button
                    //         onClick={handleSessionStart}
                    //         disabled={!selectedAdvertiserIds || loadingSession}
                    //         className={`w-full px-3 py-1.5 mt-4 text-sm md:text-base font-medium rounded-lg transition-all 
                    //         ${!selectedAdvertiserIds || loadingSession
                    //                 ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                    //                 : "bg-[#1c4546] text-white hover:bg-[#122e2e] shadow-md"}`}
                    //     >
                    //         {loadingSession ? (
                    //             <div className="flex items-center justify-center space-x-2 text-[#1c4546]">
                    //                 <div className="animate-bounce w-2 h-2 rounded-full bg-[#1c4546]"></div>
                    //                 <div className="animate-bounce200 w-2 h-2 rounded-full bg-[#1c4546]"></div>
                    //                 <div className="animate-bounce400 w-2 h-2 rounded-full bg-[#1c4546]"></div>
                    //                 <span className="ml-2">Starting Session...</span>
                    //             </div>
                    //         ) : (
                    //             "Start Session"
                    //         )}
                    //     </button>
                    // </div>