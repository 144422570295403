import React, { } from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart, Line, PieChart, Pie, Cell, CartesianGrid } from "recharts";

const ChatbotChart = ({ chatHistory }) => {

  return (
    <>
      <div
        className="overflow-auto px-2 py-0"
      >
        <div className="mt-3">
          {chatHistory?.map((chat, index) => (
            <div key={index} className="mb-6">
              <h3 className="text-md font-semibold">Q{index + 1}: {chat.question}</h3>

              <ResponsiveContainer width="100%" height={200}>
                {chat.visualizationType === "Bar Graph " || "bar" ? (
                  <BarChart data={chat.response}>
                    <CartesianGrid />
                    <XAxis dataKey="campaign" tick={{ fontSize: 12 }} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="value" fill="#1c4546" barSize={30} />
                  </BarChart>
                ) : chat.visualizationType === "Line Graph" ? (
                  <LineChart data={chat.response}>
                    <XAxis dataKey="campaign" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="value" stroke="#1c4546" />
                  </LineChart>
                ) : (
                  <PieChart>
                    <Pie data={chat.response} dataKey="value" nameKey="campaign" cx="50%" cy="50%" outerRadius={80} fill="#1c4546">
                      {chat.response.map((entry, idx) => (
                        <Cell key={`cell-${idx}`} fill={["#1c4546", "#4a90e2", "#ff7300"][idx % 3]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                )}
              </ResponsiveContainer>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default ChatbotChart
